<template>
  <div class="questions">
       <!-- CONTENIDO -->
    <div class="container">
      <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-8">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Preguntas frecuentes</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Preguntas frecuentes</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
       <div class="row" >
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Programa de Conectividad en Sitios Públicos 2020-2021</h3>
            </div>
            <div class="panel-collapse collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12">
                    <label>¿Cómo puedo conocer la base de datos de los sitios públicos prioritarios?</label>
                    <p>Puedes descargar la Base de Datos directamente desde el portal de datos abiertos del Gobierno Federal o en la página <a href="http://coberturauniversal.gob.mx/conectividad-sitios-publicos"
                        target="_blank">coberturauniversal.gob.mx/conectividad-sitios-publicos</a></p>
                    <hr />
                    <label>¿Cómo puedo proponer sitios públicos al Programa?</label>
                    <p>Envíanos un correo electrónico a: <a href="mailto:captura.pegasus@sct.gob.mx">captura.pegasus@sct.gob.mx</a></p>
                    <hr />
                    <label>¿Cómo se actualiza el Programa de Conectividad en Sitios Públicos?</label>
                    <p>Se consideran las Localidades de Atención Prioritaria de Cobertura Social que el Programa de Cobertura Social de la SCT actualiza anualmente, así como la demanda de conectividad registrada en el Repositorio Único de Sitios
                      Públicos conectados y por Conectar.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Repositorio Único de Sitios Públicos conectados y por conectar</h3>
            </div>
            <div class="panel-collapse collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12">
                    <label>¿Cómo puedo ser usuario del Repositorio Único?</label>
                    <p>Debes contar con una designación de la Dependencia o Entidad responsable del(los) Sitio(s) Público(s) para que puedas registrarte.</p>
                    <hr />
                    <label>¿Cómo registro un sitio público en el Repositorio único?</label>
                    <p>Debes contar con una designación de la Dependencia o Entidad responsable del(los) Sitio(s) Público(s) para que puedas registrarte como usuario del Repositorio, sino perteneces a la institución, envíanos un correo electrónico a:
                      <a href="mailto:repositoriounico.sitiospublicos@sct.gob.mx">repositoriounico.sitiospublicos@sct.gob.mx</a> para proporcionarte mayor información.
                    </p>
                    <hr />
                    <label>¿Cómo puedo consultar todos los sitios públicos registrados en el Repositorio Único?</label>
                    <p>Puedes descargar la Base de Datos directamente desde el portal de datos abiertos del Gobierno Federal o en la página <a href="http://coberturauniversal.gob.mx/repositorio-unico"
                        target="_blank">coberturauniversal.gob.mx/repositorio-unico</a></p>
                    <hr />
                    <label>¿Quién puede registrar, validar y actualizar los sitios públicos del Repositorio Único?</label>
                    <p>Solo los usuarios registrados en el Repositorio Único.</p>
                    <hr />
                    <label>¿Cómo puedo saber si existen sitios públicos conectados en mi localidad?</label>
                    <p>Puedes descargar la Base de Datos directamente desde el portal de datos abiertos del Gobierno Federal o en la página <a href="http://coberturauniversal.gob.mx/repositorio-unico"
                        target="_blank">coberturauniversal.gob.mx/repositorio-unico</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Aldeas inteligentes, Bienestar sostenible</h3>
            </div>
            <div class="panel-collapse collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12">
                    <label>¿Cómo puedo saber si existen Aldeas Inteligentes en mi localidad?</label>
                    <p>Revisa la infografías publicadas en la página <a href="http://:coberturauniversal.gob.mx/aldeas-inteligentes" target="_blank">coberturauniversal.gob.mx/aldeas-inteligentes</a></p>
                    <hr />
                    <label>¿Cómo puedo proponer proyectos productivos para que sean parte de una Aldea Inteligente?</label>
                    <p>Envíanos un correo electrónico a: <a href="mailto:captura.pegasus@sct.gob.mx">captura.pegasus@sct.gob.mx</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"
export default {
  name: 'questions',
  data(){
    return{
      isView:false
    }
  },
   methods: {
    back(){
        router.replace('/')
    },
  },
}
</script>

